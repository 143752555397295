import './App.css';
import React, { useState, useEffect } from "react"
import io from "socket.io-client";


function App() {
  const [socket, setSocket] = useState(null);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const newSocket = io.connect("138.201.23.126");

    newSocket.on("connect", () => {
      console.log("Connected client ", newSocket.id);
      setSocket(newSocket);
    });

    return () => {
      if (newSocket) {
        newSocket.disconnect();
      }
    };
  }, []);

  const sendMessage = (e) => {
    e.preventDefault();

    console.log(socket)
    if (socket) {
      const data={
        message:message,
        sender_id:123,
        receiver_id:456
      }
      socket.emit("sendMessage", data);
    } else {
      console.error("Socket is not initialized.");
    }
  };


  return (
    <>
      <h3>Austta chat client</h3>

      <div className=' w-25 p-3'>
        <div class="form-group">
          <label for="exampleInputEmail1">Test checking</label>
          <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" value={message} onChange={(e) => setMessage(e.target.value)} />
          <button className='btn-primary btn-sm my-1' onClick={sendMessage}> send</button>
        </div>

      </div>
    </>
  );
}

export default App;
